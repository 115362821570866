import { motion } from "framer-motion";
import { Lock, LogIn, User } from "lucide-react";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../providers/admin/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

// Styled components

const Login: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-gray-800 p-8 rounded-lg shadow-lg w-96"
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-100">
          Admin Login
        </h2>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            setIsSubmitting(true);
            try {
              await authContext.login(formData.email, formData.password);
              navigate("/admin/tenants");
            } catch (error) {
              console.error("Login failed", error);
              setIsSubmitting(false);
            }
          }}
        >
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Email
            </label>
            <div className="relative">
              <User
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={18}
              />
              <input
                type="email"
                id="username"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="pl-10 w-full p-2 bg-gray-700 border border-gray-600 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-100"
                placeholder="Enter your email address"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Password
            </label>
            <div className="relative">
              <Lock
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={18}
              />
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="pl-10 w-full p-2 bg-gray-700 border border-gray-600 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-100"
                placeholder="Enter your password"
                required
              />
            </div>
          </div>
          <motion.button
            whileHover={!isSubmitting ? { scale: 1.05 } : undefined}
            whileTap={!isSubmitting ? { scale: 0.95 } : undefined}
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-blue-600 text-white p-2 rounded transition duration-300 flex items-center justify-center ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-700"
            }`}
          >
            <LogIn className="mr-2" size={18} />
            {isSubmitting ? "Logging in..." : "Login"}
          </motion.button>
        </form>
        <div className="text-center mt-4">
          <Link 
            to="/forgot-password" 
            className="text-blue-400 hover:text-blue-300 text-sm transition duration-300"
          >
            Forgot Password?
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;
